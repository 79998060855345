.slide-left-left-150px-250ms-enter-active {
  animation: slide-left-in 250ms ease-in-out forwards;
}

.slide-left-left-150px-250ms-exit-active {
  animation: slide-left-out 250ms ease-in-out forwards;
}

/* --- */

.slide-right-right-150px-250ms-enter-active {
  animation: slide-right-in 250ms ease-in-out forwards;
}

.slide-right-right-150px-250ms-exit-active {
  animation: slide-right-out 250ms ease-in-out forwards;
}

/* --- */

.slide-right-left-150px-250ms-enter-active {
  animation: slide-right-in 250ms ease-in-out forwards;
}

.slide-right-left-150px-250ms-exit-active {
  animation: slide-left-out 250ms ease-in-out forwards;
}

/* --- */

.slide-left-right-150px-250ms-enter-active {
  animation: slide-left-in 250ms ease-in-out forwards;
}

.slide-left-right-150px-250ms-exit-active {
  animation: slide-right-out 250ms ease-in-out forwards;
}

@keyframes slide-left-in {
  0% {
    opacity: 0;
    transform: translateX(-150px); /*-150px*/
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-left-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-150px); /*-150px*/
  }
}

@keyframes slide-right-in {
  0% {
    opacity: 0;
    transform: translateX(150px); /*-150px*/
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-right-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(150px); /*-150px*/
  }
}

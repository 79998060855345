@font-face {
  font-family: 'NextArt';
  src: url('NEXT_ART_Regular.woff2') format('woff2');
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'NextArt';
  src: url('NEXT_ART_Light.woff2') format('woff2');
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: 'NextArt';
  src: url('NEXT_ART_SemiBold.woff2') format('woff2');
  font-weight: 600; /* SemiBold */
  font-style: normal;
}

@font-face {
  font-family: 'NextArt';
  src: url('NEXT_ART_Bold.woff2') format('woff2');
  font-weight: 700; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'NextArt';
  src: url('NEXT_ART_Heavy.woff2') format('woff2');
  font-weight: 900; /* Heavy (ExtraBold) */
  font-style: normal;
}

.slide-left-left-100per-enter-active {
  animation: slide-left-in 500ms ease-in-out forwards;
}

.slide-left-left-100per-exit-active {
  animation: slide-left-out 500ms ease-in-out forwards;
}

/* --- */

.slide-right-right-100per-enter-active {
  animation: slide-right-in 500ms ease-in-out forwards;
}

.slide-right-right-100per-exit-active {
  animation: slide-right-out 500ms ease-in-out forwards;
}

@keyframes slide-left-in {
  0% {
    opacity: 0;
    transform: translateX(-100%); /*-150px*/
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-left-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%); /*-150px*/
  }
}

@keyframes slide-right-in {
  0% {
    opacity: 0;
    transform: translateX(100%); /*-150px*/
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-right-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100%); /*-150px*/
  }
}

.slide-left-left-150px-enter-active {
  animation: slide-left-in 500ms ease-in-out forwards;
}

.slide-left-left-150px-exit-active {
  animation: slide-left-out 500ms ease-in-out forwards;
}

/* --- */

.slide-right-right-150px-enter-active {
  animation: slide-right-in 500ms ease-in-out forwards;
}

.slide-right-right-150px-exit-active {
  animation: slide-right-out 500ms ease-in-out forwards;
}

/* --- */

.slide-right-left-150px-enter-active {
  animation: slide-right-in 500ms ease-in-out forwards;
}

.slide-right-left-150px-exit-active {
  animation: slide-left-out 500ms ease-in-out forwards;
}

/* --- */

.slide-left-right-150px-enter-active {
  animation: slide-left-in 500ms ease-in-out forwards;
}

.slide-left-right-150px-exit-active {
  animation: slide-right-out 500ms ease-in-out forwards;
}

@keyframes slide-left-in {
  0% {
    opacity: 0;
    transform: translateX(-150px); /*-150px*/
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-left-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-150px); /*-150px*/
  }
}

@keyframes slide-right-in {
  0% {
    opacity: 0;
    transform: translateX(150px); /*-150px*/
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-right-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(150px); /*-150px*/
  }
}

.slide-up-up-150px-enter-active {
  animation: slide-up-in 300ms ease-in-out forwards;
}

.slide-up-up-150px-exit-active {
  animation: slide-up-out 500ms ease-in-out forwards;
}

/* --- */

.slide-down-down-150px-enter-active {
  animation: slide-down-in 500ms ease-in-out forwards;
}

.slide-down-down-150px-exit-active {
  animation: slide-down-out 500ms ease-in-out forwards;
}

@keyframes slide-up-in {
  0% {
    opacity: 0;
    transform: translateY(-100px); /*-150px*/
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-up-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100px); /*-150px*/
  }
}

@keyframes slide-down-in {
  0% {
    opacity: 0;
    transform: translateY(100px); /*-150px*/
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-down-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100px); /*-150px*/
  }
}

.shake-enter {
  animation: shake 0.5s;
}

/*.shake-enter-active {*/
/*  animation: shake 0.5s;*/
/*}*/
/*.shake-exit {*/
/*  animation: shake 0.5s;*/
/*}*/

.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(-5px, 0);
  }
  40% {
    transform: translate(5px, 0);
  }
  60% {
    transform: translate(-5px, 0);
  }
  80% {
    transform: translate(5px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.fade300ms-enter {
  opacity: 0;
}

.fade300ms-enter-active {
  opacity: 1;
  transition: opacity 300ms linear;
}

.fade300ms-exit {
  opacity: 1;
}

.fade300ms-exit-active {
  opacity: 0;
  transition: opacity 300ms linear;
}

.fade100ms-enter {
  opacity: 0;
}

.fade100ms-enter-active {
  opacity: 1;
  transition: opacity 100ms linear;
}

.fade100ms-exit {
  opacity: 1;
}

.fade100ms-exit-active {
  opacity: 0;
  transition: opacity 100ms linear;
}

.slide-left-left-150px-250ms-enter-active {
  animation: slide-left-in 250ms ease-in-out forwards;
}

.slide-left-left-150px-250ms-exit-active {
  animation: slide-left-out 250ms ease-in-out forwards;
}

/* --- */

.slide-right-right-150px-250ms-enter-active {
  animation: slide-right-in 250ms ease-in-out forwards;
}

.slide-right-right-150px-250ms-exit-active {
  animation: slide-right-out 250ms ease-in-out forwards;
}

/* --- */

.slide-right-left-150px-250ms-enter-active {
  animation: slide-right-in 250ms ease-in-out forwards;
}

.slide-right-left-150px-250ms-exit-active {
  animation: slide-left-out 250ms ease-in-out forwards;
}

/* --- */

.slide-left-right-150px-250ms-enter-active {
  animation: slide-left-in 250ms ease-in-out forwards;
}

.slide-left-right-150px-250ms-exit-active {
  animation: slide-right-out 250ms ease-in-out forwards;
}

@keyframes slide-left-in {
  0% {
    opacity: 0;
    transform: translateX(-150px); /*-150px*/
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-left-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-150px); /*-150px*/
  }
}

@keyframes slide-right-in {
  0% {
    opacity: 0;
    transform: translateX(150px); /*-150px*/
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-right-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(150px); /*-150px*/
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(-150px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(150px);
  }
}

.fade500-enter {
  opacity: 0;
}

.fade500-enter-active {
  opacity: 1;
  transition: opacity 500ms linear;
}

.fade500-exit {
  opacity: 1;
}

.fade500-exit-active {
  opacity: 0;
  transition: opacity 500ms linear;
}
.remove-enter {
  transform: scale(1) translateY(0px);
  height: 110px;
  opacity: 1;
}

.remove-enter-active {
  transform: scale(0.85) translateY(10px);
  opacity: 0;
  height: 0px;

  transition: all 500ms ease-in-out;
}

.remove-exit {
  opacity: 1;
  transform: scale(1) translateY(0px);
  height: 110px;
}

.remove-exit-active {
  height: 0px;
  opacity: 0;

  transform: scale(0.85) translateY(10px);
  transition: all 500ms ease-in-out;
}

.page {
  position: absolute;
  left: 15px;
  right: 15px;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.scale-enter {
  opacity: 0;
  transform: scale(0.9);
}

.scale-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.scale-exit {
  opacity: 1;
}

.scale-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

/* Стили для страницы */
.page {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  background-color: lightgray;
  color: darkblue;
  border-radius: 10px;
  transition: all 0.3s ease;
}

/* Анимация входа */
.page-enter {
  opacity: 0;
  transform: translateX(100%);
}

.page-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms ease, transform 500ms ease;
}

/* Анимация выхода */
.page-exit {
  opacity: 1;
  transform: translateX(0);
}

.page-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 500ms ease, transform 500ms ease;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

import *,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  text-rendering: optimizeSpeed;
  background-color: var(--mantine-color-white);
}

[data-mantine-color-scheme='dark'] body {
  background-color: var(--mantine-color-dark-7);
}

body {
  color: var(--mantine-color-black);
}

[data-mantine-color-scheme='dark'] body {
  color: var(--mantine-color-white);
}

body {
  line-height: var(--mantine-line-height);
}

/* Для WebKit-браузеров (Chrome, Safari) */
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: inherit;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*.mantine-ActionIcon-icon {*/
/*  background-color: rgb(0, 0, 0, 0);*/
/*}*/

/*.mantine-ActionIcon-root {*/
/*  background-color: rgb(0, 0, 0, 0);*/
/*}*/
/*.mantine-ActionIcon-root:hover {*/
/*  background-color: white;*/
/*  border: none;*/
/*}*/

.mantine-ThemeIcon-root {
  --ti-size: auto;
}

.m_396ce5cb:where([data-selected]) {
  background-color: var(--mantine-primary-color-filled) !important;
  color: var(--mantine-primary-color-contrast);
}

.m_396ce5cb:where([data-in-range]) {
  background-color: var(--mantine-primary-color-light-hover);
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Bold Italic'), local('Inter-BoldItalic'),
    url(/welcome/_next/static/media/Inter-BoldItalic.4ff98cfa.woff2) format('woff2');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Italic'), local('Inter-BoldItalic'),
    url(/welcome/_next/static/media/Inter_18pt-Italic.6dbda78c.ttf) format('woff2');
  font-weight: 400;
  font-style: italic;
}

/*@font-face {*/
/*    font-family: 'Inter';*/
/*    src: local('Inter Medium Italic'), local('Inter-MediumItalic'),*/
/*    url('Inter-MediumItalic.woff2') format('woff2');*/
/*    font-weight: 500;*/
/*    font-style: italic;*/
/*}*/

@font-face {
  font-family: 'Inter';
  src: local('Inter Regular'), local('Inter-Regular'),
    url(/welcome/_next/static/media/Inter-Regular.79bc3e31.woff2) format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Black'), local('Inter-Black'),
    url(/welcome/_next/static/media/Inter-Black.eb91d11e.woff2) format('woff2');
  font-weight: 900;
  font-style: normal;
}

/*@font-face {*/
/*    font-family: 'Inter';*/
/*    src: local('Inter Black Italic'), local('Inter-BlackItalic'),*/
/*    url('Inter-BlackItalic.woff2') format('woff2');*/
/*    font-weight: 900;*/
/*    font-style: italic;*/
/*}*/

@font-face {
  font-family: 'Inter';
  src: local('Inter Semi Bold'), local('Inter-SemiBold'),
    url(/welcome/_next/static/media/Inter-SemiBold.625bb4b9.woff2) format('woff2');
  font-weight: 600;
  font-style: normal;
}

/*@font-face {*/
/*    font-family: 'Inter';*/
/*    src: local('Inter Extra Bold Italic'), local('Inter-ExtraBoldItalic'),*/
/*    url('Inter-ExtraBoldItalic.woff2') format('woff2');*/
/*    font-weight: 800;*/
/*    font-style: italic;*/
/*}*/

@font-face {
  font-family: 'Inter';
  src: local('Inter Bold'), local('Inter-Bold'),
    url(/welcome/_next/static/media/Inter-Bold.cfbbcf4c.woff2) format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Extra Bold'), local('Inter-ExtraBold'),
    url(/welcome/_next/static/media/Inter-ExtraBold.9c66df57.woff2) format('woff2');
  font-weight: 800;
  font-style: normal;
}

/*@font-face {*/
/*    font-family: 'Inter';*/
/*    src: local('Inter Italic'), local('Inter-Italic'),*/
/*    url('Inter-Italic.woff2') format('woff2');*/
/*    font-weight: normal;*/
/*    font-style: italic;*/
/*}*/

/*@font-face {*/
/*    font-family: 'Inter';*/
/*    src: local('Inter Semi Bold Italic'), local('Inter-SemiBoldItalic'),*/
/*    url('Inter-SemiBoldItalic.woff2') format('woff2');*/
/*    font-weight: 600;*/
/*    font-style: italic;*/
/*}*/

@font-face {
  font-family: 'Inter';
  src: local('Inter Medium'), local('Inter-Medium'),
    url(/welcome/_next/static/media/Inter-Medium.dea841b3.woff2) format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Geologica";
  src: local("Geologica Bold"), local("Geologica-Bold"),
    url(/welcome/_next/static/media/Geologica-Bold.559893be.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Geologica";
  src: local("Geologica Medium"), local("Geologica-Medium"),
    url(/welcome/_next/static/media/Geologica-Medium.6132eb7a.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Geologica";
  src: local("Geologica Regular"), local("Geologica-Regular"),
    url(/welcome/_next/static/media/Geologica-Regular.5f911f8a.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Geologica";
  src: local("Geologica Light"), local("Geologica-Light"),
    url(/welcome/_next/static/media/Geologica-Light.f34b22e6.ttf) format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'NextArt';
  src: url(/welcome/_next/static/media/NEXT_ART_Regular.8a70b96d.woff2) format('woff2');
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'NextArt';
  src: url(/welcome/_next/static/media/NEXT_ART_Light.bb66665d.woff2) format('woff2');
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: 'NextArt';
  src: url(/welcome/_next/static/media/NEXT_ART_SemiBold.c99cd1a7.woff2) format('woff2');
  font-weight: 600; /* SemiBold */
  font-style: normal;
}

@font-face {
  font-family: 'NextArt';
  src: url(/welcome/_next/static/media/NEXT_ART_Bold.9d3d31f4.woff2) format('woff2');
  font-weight: 700; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'NextArt';
  src: url(/welcome/_next/static/media/NEXT_ART_Heavy.039add80.woff2) format('woff2');
  font-weight: 900; /* Heavy (ExtraBold) */
  font-style: normal;
}

/*@import '../model/fonts/sf-pro-text/stylesheet.css';*/



/* @media screen and (min-width: 58em) {
  .box[data-background='flash'] {
    transform: translateX(65%);
  }

  .box[data-background='potPink'] {
    transform: translateX(-80%);
  }
}

@media screen and (min-width: 62em) {
  .box[data-background='flash'] {
    transform: translateX(70%);
  }
}

@media screen and (min-width: 75em) {
  .box[data-background='flash'] {
    transform: translateX(100%);
  }

  .box[data-background='potPink'] {
    transform: translateX(-110%);
  }
}

@media screen and (min-width: 88em) {
  .box[data-background='flash'] {
    transform: translateX(100%);
  }

  .box[data-background='potPink'] {
    transform: translateX(-110%);
  }
} */

.styles_box__OYMjs[data-background='flash'] {
  transform: translateX(45%);
}

.styles_box__OYMjs[data-background='potPink'] {
  transform: translateX(-60%);
}

.styles_item__JzUCg {
  transition: all 0.2s ease-in-out;
  cursor: default;
}

.styles_item__JzUCg:hover {
  transform: scale(1.1);
}

.style_root__Wekoz {
  display: flex;
  flex-direction: column-reverse;
  gap: 34px;
}

/* plans */

.style_grid_wrapper__4grr2 {
  display: grid;
  gap: 24px;
  grid-template-rows: 244px 244px 244px;
  grid-template-areas:
    'p1 p1 p1 p1 p1 p1 p2 p2 p2 p8 p8 p8 '
    'p3 p3 p3 p4 p4 p4 p5 p5 p5 p6 p6 p6'
    'p3 p3 p3 p7 p7 p7 p9 p9 p9 p10 p10 p10';
  grid-template-columns: repeat(auto-fill, 1fr);
}

@media (max-width: 1120px) {
  .style_grid_wrapper__4grr2 {
    gap: 16px;
    grid-template-areas:
      'p1 p2'
      'p3 p3'
      'p4 p5'
      'p6 p7';
  }
}

@media (max-width: 800px) {
  .style_grid_wrapper__4grr2 {
    grid-template-areas: 'p1' 'p2' 'p3' 'p4' 'p5' 'p6' 'p7';
    grid-template-rows: repeat(7, 200px);
    box-sizing: border-box;
  }
}

/* questions */
.style_chevron__TmUGa[data-rotate] {
    transition: all 0.4s;
    justify-content: center;
    transform: rotate(45deg);
  }


.slide-up-up-150px-enter-active {
  animation: slide-up-in 300ms ease-in-out forwards;
}

.slide-up-up-150px-exit-active {
  animation: slide-up-out 500ms ease-in-out forwards;
}

/* --- */

.slide-down-down-150px-enter-active {
  animation: slide-down-in 500ms ease-in-out forwards;
}

.slide-down-down-150px-exit-active {
  animation: slide-down-out 500ms ease-in-out forwards;
}

@keyframes slide-up-in {
  0% {
    opacity: 0;
    transform: translateY(-100px); /*-150px*/
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-up-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100px); /*-150px*/
  }
}

@keyframes slide-down-in {
  0% {
    opacity: 0;
    transform: translateY(100px); /*-150px*/
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-down-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100px); /*-150px*/
  }
}

.shake-enter {
  animation: shake 0.5s;
}

/*.shake-enter-active {*/
/*  animation: shake 0.5s;*/
/*}*/
/*.shake-exit {*/
/*  animation: shake 0.5s;*/
/*}*/

.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(-5px, 0);
  }
  40% {
    transform: translate(5px, 0);
  }
  60% {
    transform: translate(-5px, 0);
  }
  80% {
    transform: translate(5px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

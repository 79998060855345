/* @media screen and (min-width: 58em) {
  .box[data-background='flash'] {
    transform: translateX(65%);
  }

  .box[data-background='potPink'] {
    transform: translateX(-80%);
  }
}

@media screen and (min-width: 62em) {
  .box[data-background='flash'] {
    transform: translateX(70%);
  }
}

@media screen and (min-width: 75em) {
  .box[data-background='flash'] {
    transform: translateX(100%);
  }

  .box[data-background='potPink'] {
    transform: translateX(-110%);
  }
}

@media screen and (min-width: 88em) {
  .box[data-background='flash'] {
    transform: translateX(100%);
  }

  .box[data-background='potPink'] {
    transform: translateX(-110%);
  }
} */

.box[data-background='flash'] {
  transform: translateX(45%);
}

.box[data-background='potPink'] {
  transform: translateX(-60%);
}

import *,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  text-rendering: optimizeSpeed;
  background-color: light-dark(
    var(--mantine-color-white),
    var(--mantine-color-dark-7)
  );
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  line-height: var(--mantine-line-height);
}

/* Для WebKit-браузеров (Chrome, Safari) */
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: inherit;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*.mantine-ActionIcon-icon {*/
/*  background-color: rgb(0, 0, 0, 0);*/
/*}*/

/*.mantine-ActionIcon-root {*/
/*  background-color: rgb(0, 0, 0, 0);*/
/*}*/
/*.mantine-ActionIcon-root:hover {*/
/*  background-color: white;*/
/*  border: none;*/
/*}*/

.mantine-ThemeIcon-root {
  --ti-size: auto;
}

.m_396ce5cb:where([data-selected]) {
  background-color: var(--mantine-primary-color-filled) !important;
  color: var(--mantine-primary-color-contrast);
}

.m_396ce5cb:where([data-in-range]) {
  background-color: var(--mantine-primary-color-light-hover);
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

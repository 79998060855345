@import 'animations/slideHorizontally100%.css';
@import 'animations/slideHorizontally150px.css';
@import 'animations/slideVertically150px.css';
@import 'animations/shake.css';
@import 'animations/fade300ms.css';
@import 'animations/fade100ms.css';
@import 'animations/slideHorizontally150px250ms.css';

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(-150px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(150px);
  }
}

.fade500-enter {
  opacity: 0;
}

.fade500-enter-active {
  opacity: 1;
  transition: opacity 500ms linear;
}

.fade500-exit {
  opacity: 1;
}

.fade500-exit-active {
  opacity: 0;
  transition: opacity 500ms linear;
}
.remove-enter {
  transform: scale(1) translateY(0px);
  height: 110px;
  opacity: 1;
}

.remove-enter-active {
  transform: scale(0.85) translateY(10px);
  opacity: 0;
  height: 0px;

  transition: all 500ms ease-in-out;
}

.remove-exit {
  opacity: 1;
  transform: scale(1) translateY(0px);
  height: 110px;
}

.remove-exit-active {
  height: 0px;
  opacity: 0;

  transform: scale(0.85) translateY(10px);
  transition: all 500ms ease-in-out;
}

.page {
  position: absolute;
  left: 15px;
  right: 15px;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.scale-enter {
  opacity: 0;
  transform: scale(0.9);
}

.scale-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.scale-exit {
  opacity: 1;
}

.scale-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

/* Стили для страницы */
.page {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  background-color: lightgray;
  color: darkblue;
  border-radius: 10px;
  transition: all 0.3s ease;
}

/* Анимация входа */
.page-enter {
  opacity: 0;
  transform: translateX(100%);
}

.page-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms ease, transform 500ms ease;
}

/* Анимация выхода */
.page-exit {
  opacity: 1;
  transform: translateX(0);
}

.page-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 500ms ease, transform 500ms ease;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

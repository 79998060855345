.root {
  display: flex;
  flex-direction: column-reverse;
  gap: 34px;
}

/* plans */

.grid_wrapper {
  display: grid;
  gap: 24px;
  grid-template-rows: 244px 244px 244px;
  grid-template-areas:
    'p1 p1 p1 p1 p1 p1 p2 p2 p2 p8 p8 p8 '
    'p3 p3 p3 p4 p4 p4 p5 p5 p5 p6 p6 p6'
    'p3 p3 p3 p7 p7 p7 p9 p9 p9 p10 p10 p10';
  grid-template-columns: repeat(auto-fill, 1fr);
}

@media (max-width: 1120px) {
  .grid_wrapper {
    gap: 16px;
    grid-template-areas:
      'p1 p2'
      'p3 p3'
      'p4 p5'
      'p6 p7';
  }
}

@media (max-width: 800px) {
  .grid_wrapper {
    grid-template-areas: 'p1' 'p2' 'p3' 'p4' 'p5' 'p6' 'p7';
    grid-template-rows: repeat(7, 200px);
    box-sizing: border-box;
  }
}

/* questions */
.chevron {
  &[data-rotate] {
    transition: all 0.4s;
    justify-content: center;
    transform: rotate(45deg);
  }
}

@font-face {
  font-family: "Geologica";
  src: local("Geologica Bold"), local("Geologica-Bold"),
    url("Geologica-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Geologica";
  src: local("Geologica Medium"), local("Geologica-Medium"),
    url("Geologica-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Geologica";
  src: local("Geologica Regular"), local("Geologica-Regular"),
    url("Geologica-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Geologica";
  src: local("Geologica Light"), local("Geologica-Light"),
    url("Geologica-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

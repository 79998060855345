.fade100ms-enter {
  opacity: 0;
}

.fade100ms-enter-active {
  opacity: 1;
  transition: opacity 100ms linear;
}

.fade100ms-exit {
  opacity: 1;
}

.fade100ms-exit-active {
  opacity: 0;
  transition: opacity 100ms linear;
}

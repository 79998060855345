.fade300ms-enter {
  opacity: 0;
}

.fade300ms-enter-active {
  opacity: 1;
  transition: opacity 300ms linear;
}

.fade300ms-exit {
  opacity: 1;
}

.fade300ms-exit-active {
  opacity: 0;
  transition: opacity 300ms linear;
}
